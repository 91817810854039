@import 'variables';
@import 'mixins';
@import 'theme';
@import 'responsive';
@import 'mdc';

* {
  box-sizing: border-box;
}

body, html {
  padding: 0;
  margin: 0;
  color: $primary-font;
  height: 100%;
  /* Disables pull-to-refresh and overscroll glow effect.
     Still keeps swipe navigations.
     Source: https://developers.google.com/web/updates/2017/11/overscroll-behavior
  */
  overscroll-behavior-y: none;
}

.ltr {
  direction: ltr;

  .all-buttons img {
    margin-right: 12px;
    margin-left: 3px;
  }

  .menu-button {
    margin-right: 9px;
  }
}

.rtl {
  direction: rtl;

  textarea {
    direction: rtl;
  }

  #waiter .buttons {
    direction: rtl;
  }

  .menu-button {
    margin-left: 9px;
  }

  .all-buttons img {
    margin-right: 3px;
    margin-left: 12px;
  }

  li.focus {
    a, .link {
      line-height: 65px;
    }
  }

  form.search {
    mat-icon {
      right: auto !important;
      left: 3px !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.
  */
  .mat-slide-toggle-thumb-container {
    padding-right: 9px;
  }
}


#root, .router {
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: #4085a9;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1170px;
  margin: auto;
}

.no-padding {
  padding: 0;
}

.no-top-bottom-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.modifier-container, .modifier-container-small {
  backdrop-filter: blur(10px);
}

.no-left-right-padding {
  padding-left: 0;
  padding-right: 0;
}

*:focus {
  outline: none;
}
app-settings {
  mat-accordion {
    border-top: 1px dashed #ccc;
  }
  mat-expansion-panel {
    box-shadow: none !important;
    border-bottom: 1px dashed #ccc;
    background-color: rgb(245, 245, 247) !important;
  }
  mat-expansion-panel:last-child {
    border-bottom: 0 !important;
  }
  .mat-expansion-panel-body {
    padding: 0;
  }
  .mat-expansion-panel-header {
    padding: 0;
  }
}

.cdk-overlay-backdrop {
  background-color: rgba(68, 73, 91, 0.63)  !important;
}

.mat-bottom-sheet-container {
  background-color: $primary-background;
}

.default-currency {
  font-size: 12px;
  text-align: right;
  font-weight: normal;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
  */
.mat-form-field-flex {
  background-color: $form-feild-background !important;
}

.mat-mdc-input-element {
  background-color: $form-feild-background !important;
  color: $form-field-text !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
  */
.mat-select-arrow {
  color: $radio-checkbox-border;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
  */
.mat-select-value {
  color: $form-field-text;
}

.mat-mdc-dialog-container {
  padding: 0 !important;
}

.mat-mdc-dialog-content {
  padding: 0 !important;
  margin: 0 !important;
}


::ng-deep .dialog-container .mat-mdc-dialog-container {
  background-color: $primary-background;
  padding: 0;
  overflow: inherit;
}

.mini-dialog {
  .mat-mdc-dialog-container {
    position: relative;
    border-radius: 1em;
  }

  .content {
    @include flexbox();
    @include align-items(center);
    margin: auto;
    width: fit-content;
    height:100%;
    font-size: 16px;
  }

  .items {
    margin-bottom: 15px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

