$mobile-width: 700px;

// Base Colors:
$primary-font: #14152D;
$secondary-font: #14152D;
$primary-background: #F9F9FB;

// Buttons:
$confirm-button-background: #14152D;
$confirm-button-text: #F9F9FB;
$cancel-button-text: #14152D;
$cancel-button-background: #F9F9FB;
$language-buttons-background: $confirm-button-background;
$language-buttons-text: $confirm-button-text;

// Radios and checkboxes:
$radio-checkbox-filler: #E61E2B;
$radio-checkbox-border: #14152D;

// Main Menu Layout:
$super-section-name: $primary-font;
$super-section-title: $primary-font;
$cart-preview-background: #FF8A00;
$cart-preview-font: white;
$scrollspy-selected: $primary-font;
$scrollspy-font: $primary-font;
$scrollspy-selected-font: $cart-preview-font;
$top-bar-bg: $primary-background;
$scrollspy-bg: $top-bar-bg;

// Item cards:
$item-cards-background: white;
$item-name: $primary-font;
$item-description: $secondary-font;
$item-price: $primary-font;
$add-remove-items:  #E61E2B;
$item-img-backdrop: rgba(255,255,255,0.75);

// Bottom Menu:
$bottom-menu-background: #F9F9FB;
$bottom-menu-border: none;

// App Launch (splash + supersections):
$super-section-background: #14152D;
$super-section-font: white;
$super-section-lines: white;
$splash-background: $super-section-background;
$table-name-color: $super-section-font;
$menu-button-display: block;

// Order Form:
$form-feild-background: none;
$form-field-text: none;

// Cart page:
$voucher-receipt-text: #FF8A00;
$clear-cart-background: #E61E2B;
$cart-buttons-border: #EAECED;
$cart-items-bg: $item-cards-background;
$order-notes-display: block;

// Grid Supersections:
$divider-background: $primary-background;
$divider-text: $cancel-button-text;
$divider-border: $confirm-button-background;
